<template>
  <div>
    <rxNavBar title="选择房源" :androidOrIOSFlag="androidOrIOSFlag"></rxNavBar>
    <div class="content" >
      <!-- 无合同时 -->
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <!--                <van-list-->
        <!--                        v-model="loading"-->
        <!--                        :finished="finished"-->
        <!--                        :finished-text="noContent? '':'已经到底了'"-->
        <!--                        @load="this.onLoad"-->
        <!--                >-->
        <div class="content_centre" v-for="(item,index) in dataList" :key="index"  @click="typeChoose(item)">
          <!-- 状态 -->
          <div  class="content_title">{{item.contractStatusInfo}}</div>
          <!-- 合同编号 -->
          <div class="content_contract"><span>合同编号:</span><span>{{item.contractCode}}</span></div>
          <!-- 内容 -->
          <div class="content_span">{{item.roomDetailedAddress}}</div>
        </div>
        <!--                </van-list>-->
      </van-pull-refresh>
      <common-empty v-if="noContent"></common-empty>
    </div>
  </div>

</template>

<script>
import {NavBar} from "vant";
import  {contractList,complaintApplicationListInit} from "../../../getData/getData";
import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import Vue from 'vue';
import { List } from 'vant';
Vue.use(List);
import { PullRefresh } from 'vant';
import eventBus from "../../../components/rongxun/rx-navBar/envbus";
Vue.use(PullRefresh);

export default {
  name: "SelectRoom",
  data(){
    return{
      dataList:[],
      pageCount:'',//总页数
      loading: false,
      finished: false,
      currentPage:'1',//当前页
      numberPage:'10',//每页条数
      type:'1',//租客合同类型
      isLoading: false,
      noContent:false,//显示空页面
      chooseType:'',//页面选择类型 1 维修 2 保洁
      dictionaryTitle:'',//维修类型
      id:'',
      androidOrIOSFlag:"true",
    }
  },
  components:{
    [NavBar.name]:NavBar,
    [List.name]:List,
    [PullRefresh.name]:PullRefresh,
    rxNavBar
  },
  created() {
    if(this.$route.query.jumpType == "complaintAcceptance"){
      this.androidOrIOSFlag = "false";
    }
    this.chooseType=this.$route.query.changeType
    this.dictionaryTitle=this.$route.query.dictionaryTitle;//维修标题
    this.id=this.$route.query.id//维修状态码
    this.userRepairAddressList();
  },
  //路由跳转时销毁全局bus里的监听事件
  beforeDestroy() {
    eventBus.$off('SelectRoom')
  },
  methods:{
    //页面跳转

    typeChoose(house){
      let params = {
        searchInfo:house.roomDetailedAddress,
        setHouseId:house.id,
        searchId:house.roomHouseId
      }
      //  //传递一个map，chooseCommunity是key，params是value
      eventBus.$emit('SelectRoom',params);
      //调用router回退页面
      this.$router.go(-1);

    },

    onRefresh() {//刷新
      setTimeout(() => {
        this.default();
        this.isLoading = false;
        this.userRepairAddressList()
      },800);
    },
    default(){//默认值
      this.dataList=[]
    },
    //房源选择初始化
    userRepairAddressList: function () {
      var that = this
      let initData = {
        user_id:globaluserId(),
      }
      complaintApplicationListInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          if (response.data.code=='0'){
            that.dataList=response.data.data.housingResourcesList
          }
        })
      })
    },


  }

}
</script>

<style scoped>

.content{
  width: 100%;
  height: auto;
  margin-top: 30px;
}
.content_centre{
  width: 92%;
  height: auto;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #FFFF;
  padding-bottom: 15px;
  margin-bottom: 15px;

}
.content_title{
  border-radius: 8px 1px;
  background-image: linear-gradient(to right,#FFAE82, #FB5F2E);
  width: 42px;
  height: 17px;
  border-bottom-left-radius: 0;
  text-align: center;
  font-size: 8px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.7);
  font-family: PingFangSC-Regular;

}
/* 合同编号 */
.content_contract{
  color: #EB8671;
  margin-top: 5px;
  margin-left: 15px;
  font-size: 15px;
  font-family: PingFangSC-Semibold;
}
.content_centre  div:not(:nth-of-type(1)){
  margin-left: 15px;
}
/* 内容 */
.content_span{
  margin-top: 5px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Semibold;
}

</style>
